import {Card, Group, Radio, Text, Textarea} from "@mantine/core";

export const QuestionAfter = ({item,key, onChange}) => (
    <Card shadow="sm" p="lg" radius={0} key={"data-" + key} mb={24}>
        <Text className={"item-text"}>{item.body}</Text>

         <>
             <Radio.Group
                 name={"question_point_" + item.id}
                 description={"Sence eğitim öncesi kaçtın?"}
                 size={"md"}
                 onChange={(val) => onChange(item.id,Number(val),'answer_after_before')}
             >
                 <Group mt="xs" spacing={"xl"}>
                     <Radio value="1" label="1" color={"violet"} />
                     <Radio value="2" label="2" color={"violet"}/>
                     <Radio value="3" label="3" color={"violet"}/>
                     <Radio value="4" label="4" color={"violet"}/>
                     <Radio value="5" label="5" color={"violet"}/>
                     <Radio value="6" label="6" color={"violet"}/>
                     <Radio value="7" label="7" color={"violet"}/>
                     <Radio value="8" label="8" color={"violet"}/>
                     <Radio value="9" label="9" color={"violet"}/>
                     <Radio value="10" label="10" color={"violet"}/>
                 </Group>

             </Radio.Group>

             <Radio.Group
                 name={"question_new_point_" + item.id}
                 description={"Eğitim sonrası kendine kaç veriyorsun?"}
                 size={"md"}
                 mt={25}
                 onChange={(val) => onChange(item.id,Number(val),'answer_after_after')}
             >
                 <Group mt="xs" spacing={"xl"}>
                     <Radio value="1" label="1" color={"violet"} />
                     <Radio value="2" label="2" color={"violet"}/>
                     <Radio value="3" label="3" color={"violet"}/>
                     <Radio value="4" label="4" color={"violet"}/>
                     <Radio value="5" label="5" color={"violet"}/>
                     <Radio value="6" label="6" color={"violet"}/>
                     <Radio value="7" label="7" color={"violet"}/>
                     <Radio value="8" label="8" color={"violet"}/>
                     <Radio value="9" label="9" color={"violet"}/>
                     <Radio value="10" label="10" color={"violet"}/>
                 </Group>
             </Radio.Group>

         </>



    </Card>
)

export default QuestionAfter
