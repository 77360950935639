import {Card, Group, Radio, Text, Textarea} from "@mantine/core";

export const QuestionLife = ({item,survey,key, onChange, size = 'md'}) => (
    <Card shadow="sm" p="lg" radius={0} key={"data-" + key} mb={24}>
        <Text className={"item-text"} style={{marginBottom:0}}>{item.body}</Text>
        <Text className={"item-text item-min-text"}>{item.description}</Text>

        <Radio.Group
            name={"question_point_" + item.id}
            description={"Puan seç"}
            size={size}
            onChange={(val) => onChange(item.id,val)}
        >
            <Group mt="xs" spacing={"xl"}>
                <Radio value="1" label="1" color={"violet"} />
                <Radio value="2" label="2" color={"violet"}/>
                <Radio value="3" label="3" color={"violet"}/>
                <Radio value="4" label="4" color={"violet"}/>
                <Radio value="5" label="5" color={"violet"}/>
                <Radio value="6" label="6" color={"violet"}/>
                <Radio value="7" label="7" color={"violet"}/>
                <Radio value="8" label="8" color={"violet"}/>
                <Radio value="9" label="9" color={"violet"}/>
                <Radio value="10" label="10" color={"violet"}/>
            </Group>
        </Radio.Group>


    </Card>
)

export default QuestionLife
