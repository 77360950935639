import Cookies from 'js-cookie'

export const setSession = (token) => {
    return Cookies.set('__session',token);
}


export const getSession = () => {
    return Cookies.get('__session')
}


export const setQuestionInfo = (question) => {
    return localStorage.setItem('question', JSON.stringify(question));
}

export const removeQuestionInfo = () => {
    return localStorage.removeItem('question');

}

export const getQuestionInfo = () => {

    let user = localStorage.getItem('question');

    if (user !== undefined) {

        return JSON.parse(user);

    }

    return false;


}


export const setUserInfo = (user) => {
    return localStorage.setItem('user', JSON.stringify(user));
}

export const removeUserInfo = () => {
    return localStorage.removeItem('user');

}

export const getUserInfo = () => {

    let user = localStorage.getItem('user');

    if (user !== undefined) {

        return JSON.parse(user);

    }

    return false;


}


export const logOut = () => {
    Cookies.remove('__session');
    Cookies.remove('__user');
}
