import {Group,Text,Container,Grid,Image} from "@mantine/core";
import {FaRegCopyright} from "react-icons/fa";


export const Header = () => {

    return (<footer>
        <div className="footer-top">

         <Container size={"xl"}>
             <Grid>

                 <Grid.Col lg={3} pt={85}>
                     <Image alt={''} src={'/img/logo-footer.png'} width={172} height={96}/>
                 </Grid.Col>

                 <Grid.Col lg={4} pt={79}>
                     <Text className={"text-mail"}>
                         Telefon:  0(532) 172 62 01 <br/>
                         Mail: info@houseofhuman.com<br/>
                         Adres: Cami Mahallesi, Alpay Çelen Sokak, No: 33-1, 34940 Tuzla/İstanbul
                     </Text>
                 </Grid.Col>
                 <Grid.Col lg={5}>
                    <Group position={'right'} mt={79}>
                        <Image alt={'House of Human'} src={'/img/brand.png'} width={382} height={59}/>
                    </Group>
                 </Grid.Col>
             </Grid>
         </Container>

        </div>
        <div className="footer-bottom">
           <Container size={"xl"}>
               <div>
                   <FaRegCopyright size={30}/>
                   <Text className={"text"}>
                       Tüm içerik House of Human tarafından hazırlanmıştır ve Türkiye Cumhuriyeti kanunları tarafından
                       korunmaktadır. İzinsiz çoğaltmak, yaymak, paylaşmak vesair durumlar için kanuni haklarımızı
                       kullanacağımızı bildiririz.
                   </Text>
               </div>
           </Container>
        </div>
    </footer>)

}


export default Header;
