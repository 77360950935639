import {Button, Text, TextInput, Title} from "@mantine/core";

import {useState} from "react";
import Connect from "../../lib/util/Connect";
import {useDocumentTitle} from "@mantine/hooks";
import {useForm} from "@mantine/form";
import InputMask from 'react-input-mask';
import {useNavigate, useParams} from "react-router-dom";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {notifications} from '@mantine/notifications';
import {setUserInfo} from "../../lib/util/auth";
import {pageRoute} from "../../lib/util/route";


const SurveyLogin = () => {


    useDocumentTitle('Anket Katılım');
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const modals = useModals();
    const navigate = useNavigate();



    const form = useForm({
        initialValues: {
            email: '',
            fullname: '',
            phone: '',
        },

        validate: {
            fullname: (value) => (value !== '' ? null : 'Ad Soyad girmelisiniz'),
            phone: (value) => (value !== '' ? null : 'Telefon Numarası girmelisiniz'),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi'),
        },
    });



    const setLoginSurvey = (values) => {

        setLoading(true);

        Connect().post(`surveys/${id}/check`, {
            phone: values.phone,
            email: values.email,
            full_name: values.fullname
        }).then(({data}) => {

            if (data.status) {


                notifications.show({
                    color:'teal',
                    title: 'Başarılı',
                    message: 'Ankete giriş yapıldı',
                });


                setUserInfo({
                    phone: values.phone,
                    email: values.email,
                    full_name: values.fullname,
                    answers: []
                });


                let url = pageRoute(data.survey.type);


               navigate(url+id);


            } else {
                ErrorViewing({message: "Ankete katılımınız uygun değil"}, modals);
            }


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));


    }


    return (<>

        <div id={"login"}>

            <div className="table-cell">
                <form onSubmit={form.onSubmit((values) => setLoginSurvey(values))}>

                    <Title order={1}>Ankete Katıl</Title>
                    <Text className={"text"} mt={7} mb={28}>
                        Lütfen kişisel bilgilerinizi değerlendirmenin doğruluğu için eksiksiz girin.
                    </Text>


                    <TextInput
                        withAsterisk
                        label="Adınız ve Soyadınız"
                        placeholder="Ad Soyad"
                        className={"default-input"}
                        {...form.getInputProps('fullname')}
                        mb={15}
                    />

                    <TextInput
                        withAsterisk
                        label="E-Posta"
                        placeholder="E-Posta adresinizi yazın"
                        className={"default-input"}
                        {...form.getInputProps('email')}
                        mb={15}
                    />


                    <TextInput
                        withAsterisk
                        label="Telefon"
                        placeholder="Telefon numaranızı yazın"
                        className={"default-input"}
                        // @ts-ignore
                        component={InputMask}
                        mask="+\90 (999) 999 99 99"
                        {...form.getInputProps('phone')}
                        mb={26}
                    />


                    <Button loading={loading} fullWidth className={"btn-start"} color={"dark"} type="submit">Ankete
                        Başla</Button>

                </form>
            </div>

        </div>


    </>)
}

export default SurveyLogin
