export const pageRoute = (type = '') => {


    let text = '';

    switch (type) {


        case 't':
            text = '/anket-t-method/';
            break;


        case 'swot':

            text = '/anket-swot/'

            break;

        case 'yasamdenge':

            text = '/anket-yasam-denge-carki/'

            break;

        default:
            text = '/anket/';
            break;

    }

    return text;

}
