import React, {useState} from 'react';
import {MantineProvider} from '@mantine/core';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ModalsProvider} from '@mantine/modals';
import {Provider} from 'react-redux';
import {store} from "./store";
import Content from "./lib/components/Content";
import Home from "./app/Home";
import Survey from "./app/Survey";
import SurveyResult from "./app/Survey/result";
import SurveyLogin from "./app/Survey/login";
import {Notifications} from "@mantine/notifications";
import Swot from "./app/Swot";
import Tmethod from "./app/TMethod";
import LifeCycle from "./app/LifeCycle";
import LifeCycleResult from "./app/LifeCycle/result";


function App() {


  const [user, setUser] = useState({});


  return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
          <ModalsProvider>
              <Notifications />

              <Provider store={store}>
              <BrowserRouter>
                <Content user={user}>
                  <Routes>
                    <Route path="/login" element={<Home/>}/>
                    <Route>
                      <Route path="/" index element={<Home/>}/>
                      <Route path="/anket/:id"  element={<Survey/>}/>
                      <Route path="/anket-sonuc"  element={<SurveyResult/>}/>
                      <Route path="/anket-giris/:id"  element={<SurveyLogin/>}/>
                      <Route path="/anket-swot/:id"  element={<Swot/>}/>
                      <Route path="/anket-yasam-denge-carki/:id"  element={<LifeCycle/>}/>
                      <Route path="/anket-t-method/:id"  element={<Tmethod/>}/>
                        <Route path="/anket-yasam-denge-sonuc"  element={<LifeCycleResult/>}/>

                    </Route>
                  </Routes>
                </Content>
              </BrowserRouter>
            </Provider>
          </ModalsProvider>
      </MantineProvider>
  );
}

export default App;
