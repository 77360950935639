import {Button, Text, Title} from "@mantine/core";
import {useDocumentTitle, useScrollIntoView} from "@mantine/hooks";
import {Link} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {useEffect} from "react";


const SurveyResult = () => {

    useDocumentTitle('Anket Sonucu');

    const { scrollIntoView, targetRef } = useScrollIntoView({
        offset: 0,
    });


    useEffect(()=>{

        scrollIntoView({
            duration:0,
            easing:0,
            offset:0
        });

    },[]);


    return (<>


        <div id="result">
            <div className="table-cell">
                <Title order={1}>Ankete katıldığınız için <br/> teşekkürler.</Title>
                <Text className={"text"}>Anket sonuçlarınız PDF olarak gönderilecektir. Değerlendirme anketleri
                    için <br/> danışmanınızla
                    iletişimde kalmanız gerekmektedir.</Text>

                    <Button component={Link} to={'/'} className={"btn-default"} leftIcon={<AiOutlineArrowLeft size={19}/>}>Anasayfaya dön</Button>

            </div>
        </div>


    </>)
}

export default SurveyResult
