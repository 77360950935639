import {Button, Modal, Select, Text, Title} from "@mantine/core";
import {useDisclosure, useDocumentTitle, useScrollIntoView} from "@mantine/hooks";
import React, {useEffect, useRef, useState} from "react";
import {useModals} from "@mantine/modals";
import {IoAdd} from "react-icons/io5";
import _ from "lodash";
import {Link, useNavigate} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {Chart, registerables} from 'chart.js';
import {getQuestionInfo, getUserInfo, removeQuestionInfo, removeUserInfo} from "../../lib/util/auth";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {notifications} from "@mantine/notifications";

Chart.register(...registerables);

const LifeCycleResult = () => {


    useDocumentTitle('Anket Sonucu');


    const [chartData, setChartData] = useState({
        labels: [],
        data: [],
        extraData: []
    });

    const [chartLoading, setChartLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const modals = useModals();
    const [opened, {open, close}] = useDisclosure(false);
    const [status, setStatus] = useState(false);
    const {scrollIntoView, targetRef} = useScrollIntoView({
        offset: 0,
    });
    const [question, setQuestion] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [chartLoad, setChartLoad] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [newChartLoading, setNewChartLoading] = useState(false);
    const [point, setPoint] = useState([
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 5, value: 5},
        {label: 6, value: 6},
        {label: 7, value: 7},
        {label: 8, value: 8},
        {label: 9, value: 9},
        {label: 10, value: 10},
    ]);
    const navigate = useNavigate();
    const [quesDisabled, setQuesDisabled] = useState(false);
    const [surveyId,setSurveyId] = useState('');
    const [user,setUser] = useState({});
    const [_extraData,_setExtraData] = useState([]);
    const chartRef = useRef(null);
    const newChartRef = useRef(null);



    useEffect(() => {


        let questionList = getQuestionInfo();
        let userInfo = getUserInfo();

        if (questionList && userInfo){

            setUser(userInfo);

            scrollIntoView({
                duration: 0,
                easing: 0,
                offset: 0
            });

            let _chartData = {
                labels: [],
                data: [],
                extraData: []
            };


            let _quest = [];

            questionList.data.map((item, index) => {
                _chartData.labels.push(item.body);
                _chartData.data.push(item.answer);
                _quest.push({id: item.id, body: item.body, value: ''});
            });

            setSurveyId(questionList.id);
            setQuestion(_quest);
            setChartData(_chartData);
            setChartLoad(true);
            setAnswers(questionList.answers);


        } else{
            navigate('/');
        }

    }, []);

    useEffect(() => {

        if (question.length) {
            let status = false;

            let _data = [];

            question.map((item) => {
                if (item.value === '') {
                    status = true;
                }
                _data.push(Number(item.value));

            });

            _setExtraData(_data);
            setQuesDisabled(status);
        }

    }, [question]);


    const chartSize = (type = 0) => {

        let size = [];

        /*
        * case 4:

                size = [70,700,-10,0]

                break;

            case 5:

                size = [116,800,-80,74]

                break;
        * */

        switch (chartData.labels.length) {




            default:
                size = [3,620,20,0];

        }

        return size[type];

    }


    useEffect(() => {

        if (newChartLoading) {
            chatUpdate();
        }

    }, [newChartLoading]);


    useEffect(() => {

        if (chartLoad) {
            // const ctx = document.getElementById('myChart');
            const ctx_current = chartRef.current.getContext("2d");

            // setup
            const data = {
                labels: chartData.labels,
                datasets: [{
                    label: 'Puan',
                    data: chartData.data,

                    backgroundColor: [
                        'rgba(255, 26, 104, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(0, 0, 0, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 26, 104, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(0, 0, 0, 1)'
                    ],
                    borderWidth: 1
                }]
            };

            // multiThresholdLine plugin block
            const multiThresholdLine = {
                id: 'multiThresholdLine',
                afterDatasetsDraw(chart, args, pluginOptions) {
                    const {ctx, data, scales: {r}} = chart;


                    const segments = pluginOptions.lineValue.length;

                    pluginOptions.lineValue.forEach((value, index) => {
                        const trueHeight = r.yCenter - r.top - chartSize();
                        const radius = (trueHeight / r.end * value) - Math.floor(value / 2);
                        const angleStart = Math.PI * -0.5 + (Math.PI * 2) / segments * index;
                        const angleEnd = Math.PI * -0.5 + (Math.PI * 2) / segments * (index + 1);
                        ctx.save();

                        ctx.beginPath();
                        ctx.linewWidth = 3;
                        ctx.strokeStyle = data.datasets[0].borderColor[index];

                        ctx.arc(r.xCenter, r.yCenter, radius, angleStart, angleEnd, false);
                        ctx.stroke();
                        ctx.restore();
                        ctx.lineWidth = 3;

                    })
                }
            }

            // config
            const config = {
                type: 'polarArea',
                data,
                options: {
                    responsive: true,

                    scale: {
                        min: 0,
                        max: 10,
                    },

                    plugins: {

                        multiThresholdLine: {
                            lineValue: chartData.extraData,
                        },


                        legend: {
                            position: 'top',
                        },
                    },

                },
                plugins: [multiThresholdLine]
            };

            // render init block
            const myChart = new Chart(
                ctx_current,
                config
            );
        }

    }, [chartLoad]);

    const updateAnswer = (id, val, type = '') => {

        let data = [...answers];

        _.find(data, {
            question_id: id
        }).answer = val;

        setAnswers(data);

    }

    const setUpdateItem = () => {

        user.answers = answers;

        setModalLoading(true);

        Connect().post(`surveys/${surveyId}/second`,user).then(({data}) =>{


            let _data = {...chartData};


            _data.extraData =_extraData;


          //  setChartLoading(true);

            setTimeout(() => {

                setChartData(_data);
                setChartLoading(true);
                setChartLoad(true);
                setNewChartLoading(true);
                setStatus(true);
                close();

                removeUserInfo();
                removeQuestionInfo();



                notifications.show({
                    color:'teal',
                    title: 'Başarılı',
                    message: 'Anket sonuçlarınız kaydedildi!',
                });


            }, 10);


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setModalLoading(false));

    }

    const changeReply = (id, value) => {

        updateAnswer(id,value);

        let _ques = [...question];

        let find = _.find(_ques, {
            id
        });

        if (find) {
            find.value = value
        }

        setQuestion(_ques);

    }


    const chatUpdate = () =>{

        // const ctx = document.getElementById('myChart');
        const ctx_current = newChartRef.current.getContext("2d");

        // setup
        const data = {
            labels: chartData.labels,
            datasets: [{
                label: 'Puan',
                data: chartData.data,

                backgroundColor: [
                    'rgba(255, 26, 104, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(0, 0, 0, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 26, 104, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(0, 0, 0, 1)'
                ],
                borderWidth: 1
            }]
        };

        // multiThresholdLine plugin block
        const multiThresholdLine = {
            id: 'multiThresholdLine',
            afterDatasetsDraw(chart, args, pluginOptions) {
                const {ctx, data, scales: {r}} = chart;


                const segments = pluginOptions.lineValue.length;

                pluginOptions.lineValue.forEach((value, index) => {
                    const trueHeight = r.yCenter - r.top - chartSize();
                    const radius = (trueHeight / r.end * value) - Math.floor(value / 2);
                    const angleStart = Math.PI * -0.5 + (Math.PI * 2) / segments * index;
                    const angleEnd = Math.PI * -0.5 + (Math.PI * 2) / segments * (index + 1);
                    ctx.save();

                    ctx.beginPath();
                    ctx.linewWidth = 3;
                    ctx.strokeStyle = data.datasets[0].borderColor[index];

                    ctx.arc(r.xCenter, r.yCenter, radius, angleStart, angleEnd, false);
                    ctx.stroke();
                    ctx.restore();
                    ctx.lineWidth = 3;

                })
            }
        }

        // config
        const config = {
            type: 'polarArea',
            data,
            options: {
                responsive: true,

                scale: {
                    min: 0,
                    max: 10,
                },

                plugins: {

                    multiThresholdLine: {
                        lineValue: chartData.extraData,
                    },


                    legend: {
                        position: 'top',
                    },
                },

            },
            plugins: [multiThresholdLine]
        };

        // render init block
        const myChartNew = new Chart(
            ctx_current,
            config
        );

    }


    return (<>


        <Modal
            className={"modal-result"}
            padding={"xl"} centered opened={opened} onClose={close} size={600} title="Soruları Yeniden Cevaplandır">

            <div className="list">
                {question.map((item, index) => (
                    <Select onChange={(value) => changeReply(item.id, value)} data={point} placeholder={"Puan Seç (1-10)"} mb={20} label={item.body}/>
                ))}
            </div>

            <Button loading={modalLoading} disabled={quesDisabled} onClick={() => setUpdateItem()} fullWidth color={"violet"} type={"submit"}
                    rightIcon={<IoAdd size={15}/>} mt={40}>Cevapları Kaydet</Button>

        </Modal>


        <div id="result">
            <div className="table-cell">


                {!chartLoading &&
                    <div className={"chart-responsive"} style={{width: 620, height: 620, margin: '-10px auto 0 auto'}}>
                        <canvas id="myChart" ref={chartRef}></canvas>
                    </div>
                }

                {newChartLoading &&
                    <div className={"chart-responsive"} style={{width: 620, height: 620, margin: '-10px auto 0 auto'}}>
                        <canvas id="newChart" ref={newChartRef}></canvas>
                    </div>
                }





                <Title mb={0} mt={chartSize(2)} order={3} style={{fontSize: 25}}>Ankete katıldığınız için teşekkürler.</Title>


                {!status &&
                    <>
                        <Text style={{fontSize: 13, lineHeight: '20px', marginTop: 7, marginBottom: 20}}
                              className={"text"}>Anket sonuçlarınız PDF olarak gönderilecektir.
                            İkinci anketi <br/> tamamlamak için cevaplandırma yapabilirsiniz.
                        </Text>
                        <Button onClick={open} color={"teal"} type={"submit"} rightIcon={<IoAdd size={15}/>}>Yeniden
                            Cevaplandır</Button>
                    </>
                }


                {status &&
                    <>
                        <Text style={{fontSize: 13, lineHeight: '20px', marginTop: 7, marginBottom: 20}}
                              className={"text"}>İkincil anket cevaplanırız alınmıştır. Değerlendirme anketleri
                            için <br/> danışmanınızla iletişimde kalmanız gerekmektedir.
                        </Text>
                        <Button component={Link} to={'/'} color={"dark"} pt={8} pb={8} pl={15} pr={20}
                                leftIcon={<AiOutlineArrowLeft size={15}/>}>Anasayfaya dön</Button>
                    </>
                }

            </div>
        </div>


    </>)
}

export default LifeCycleResult
