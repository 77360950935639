import {
    Button,
    Card,
    Center,
    Container,
    Divider,
    Grid,
    Group,
    Loader,
    NumberInput,
    Text,
    TextInput,
    Title
} from "@mantine/core";

import {useEffect, useState} from "react";
import {useDocumentTitle, useListState} from "@mantine/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {useModals} from "@mantine/modals";
import _ from "lodash";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {getUserInfo, removeUserInfo} from "../../lib/util/auth";
import {notifications} from "@mantine/notifications";


const Tmethod = () => {


    useDocumentTitle('T-Metodu Anketi');

    const {id} = useParams();
    const modals = useModals();
    const navigate = useNavigate();

    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [survey, setSurvey] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [user, setUser] = useState({});


    const [values, handlers] = useListState([
        {
            value: 0, label: ''
        },
        {
            value: 0, label: ''
        },
        {
            value: 0, label: ''
        },
        {
            value: 0, label: ''
        },
        {
            value: 0, label: ''
        },
        {
            value: 0, label: ''
        },
        {
            value: 0, label: ''
        },
    ]);



    useEffect(()=>{

        let arr = [];

        values.map((item) => arr.push({text: item.label, priority: item.value}));
        setAnswers(arr);

    },[values]);


    useEffect(() => {

        let userInfo = getUserInfo();


        if (userInfo) {
            getLoginControl(userInfo);
        } else {
            navigate('/');
        }

    }, []);


    const getLoginControl = (getUser = {}) => {


        Connect().post(`surveys/${id}/check`, getUser).then(({data}) => {

            if (data.status) {

                getSurvey();

                setUser(getUser);

            } else {
                ErrorViewing({message: "Ankete katılımınız uygun değildir"}, modals);
            }


        }).catch((e) => {
            ErrorViewing(e, modals);
            removeUserInfo();
            setTimeout(() => {
                navigate('/');
            }, 300);
        })


    }


    const getSurvey = () => {

        Connect().get(`surveys/${id}`).then(({data}) => {


            setPageLoading(false);

            setSurvey(data.survey);


        }).catch((e) => {
            ErrorViewing(e, modals);
            removeUserInfo();
            setTimeout(() => {
                navigate('/');
            }, 300);
        })

    }


    const tableItem = (value = 1) => {


        let find = _.find(values, {
            value: value
        });


        if (find) {
            return find.label
        }


        return 'Yanlış'


    }


    const numberChange = (item = {}) => {


        if (item.number) {


           let find = _.find(values,{
               value:item.number
           });

           if (!find){
               handlers.setItemProp(item.index, 'value', item.number);
           }


        }


        return true;

    }


    const _setSurvey = () => {

        if (user !== null){

            user.answers = answers;

            if (resultControl()) {


                setLoading(true);

                Connect().post(`surveys/${id}`, user).then(({data}) => {


                    notifications.show({
                        color: 'teal',
                        title: 'Başarılı',
                        message: 'Ankete tamamlandı',
                    });

                    navigate('/anket-sonuc');

                    removeUserInfo();


                }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));


            } else {
                ErrorViewing({message: 'Tüm Alt Başlıkları cevaplamalısınız'}, modals);
            }

        } else {
            ErrorViewing({message: 'Kullanıcı bilgileri girilmemil'}, modals);
            removeUserInfo();
            navigate('/');
        }



    }


    const resultControl = () => {

        let status = true;
        let arr = [];

        for (const res of values) {

            if (res.label === '' || res.value === 0) {
                status = false;
            }

        }


        return status;



    }


    if (pageLoading) {
        return (
            <Center style={{height: 500}}>
                <Loader color={"indigo"}/>
            </Center>
        )
    }


    return (<>


        <div className={"survey-top"}>
            <Container size={"xl"}>
                <b>#{survey?.slug}</b>
                <Title order={1}>{survey?.title}</Title>
                <Text className={"text"}>{survey?.tdetails?.subtopic_root_cause}</Text>
                <Text mt={10} className={"text"}>{survey?.tdetails?.purpose}</Text>
            </Container>
        </div>


        <div id={"survey-content"} style={{marginTop: -20}}>
            <Container size={"xl"}>
                <Text className={"question-title"}>T-METODU</Text>
                <Divider my="xl"/>


                <Card p={30}>
                    <Title order={3} mb={20}>Alt Başlıklar</Title>
                    <div className="lists">


                        {values.map((item, index) => (
                            <Group mb={20} key={`sub-list-${index}`}>
                                <TextInput
                                    style={{width: 'calc(100% - 70px)'}}
                                    placeholder="Alt Başlık"
                                    onChange={(event) => handlers.setItemProp(index, 'label', event.currentTarget.value)}
                                />
                                <NumberInput
                                    style={{width: 50}}
                                    className={"center-number"}
                                    hideControls
                                    defaultValue={0}
                                    placeholder="Sıra"
                                    max={7}
                                    value={values[index].value}
                                    onChange={(number) => numberChange({index,number})}

                                />
                            </Group>
                        ))}


                    </div>
                </Card>


                <Grid gutter={0} columns={16}>
                    <Grid.Col lg={2} className={"card-item"}>
                        <div className="card-item-top">
                            {tableItem(7)}
                        </div>
                    </Grid.Col>
                    <Grid.Col lg={2} className={"card-item"}>
                        <div className="card-item-top">
                            {tableItem(5)}
                        </div>
                    </Grid.Col>
                    <Grid.Col lg={2} className={"card-item"}>
                        <div className="card-item-top">
                            {tableItem(3)}
                        </div>
                    </Grid.Col>


                    <Grid.Col lg={4} className={"card-item"}>

                        <div className="card-item-top">

                            {tableItem(1)}
                        </div>

                    </Grid.Col>

                    <Grid.Col lg={2} className={"card-item"}>
                        <div className="card-item-top">
                            {tableItem(2)}
                        </div>
                    </Grid.Col>
                    <Grid.Col lg={2} className={"card-item"}>
                        <div className="card-item-top">
                            {tableItem(4)}
                        </div>
                    </Grid.Col>
                    <Grid.Col lg={2} className={"card-item"}>
                        <div className="card-item-top">
                            {tableItem(6)}
                        </div>
                    </Grid.Col>
                </Grid>


                <Button onClick={() => _setSurvey()} mt={20} className={"save-btn"} loading={loading} fullWidth>Anketi
                    Tamamla</Button>

            </Container>
        </div>


    </>)
}

export default Tmethod
