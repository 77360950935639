import {Card, Group, Radio, Text, Textarea} from "@mantine/core";

export const Question = ({item,survey,key, onChange}) => (
    <Card shadow="sm" p="lg" radius={0} key={"data-" + key} mb={24}>
        <Text className={"item-text"}>{item.body}</Text>

        {survey?.answertype === 'number' &&
            <Radio.Group
                name={"question_point_" + item.id}
                description={"Puan seç"}
                size={"md"}
                onChange={(val) => onChange(item.id,val)}
            >
                <Group mt="xs" spacing={"xl"}>
                    <Radio value="1" label="1" color={"violet"} />
                    <Radio value="2" label="2" color={"violet"}/>
                    <Radio value="3" label="3" color={"violet"}/>
                    <Radio value="4" label="4" color={"violet"}/>
                    <Radio value="5" label="5" color={"violet"}/>
                    <Radio value="6" label="6" color={"violet"}/>
                    <Radio value="7" label="7" color={"violet"}/>
                    <Radio value="8" label="8" color={"violet"}/>
                    <Radio value="9" label="9" color={"violet"}/>
                    <Radio value="10" label="10" color={"violet"}/>
                </Group>
            </Radio.Group>
        }

        {survey?.answertype === 'text' &&
            <Textarea
                placeholder="Yanıtınız"
                withAsterisk
                className={"list-textarea"}
                onChange={(event) => onChange(item.id,event.currentTarget.value)}
            />
        }

    </Card>
)

export default Question
