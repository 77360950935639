import {Button, Container, Grid, Image, Text, TextInput, Title, Tooltip} from "@mantine/core";
import {AiOutlineQuestionCircle} from "react-icons/ai";
import {useForm} from "@mantine/form";
import {useDocumentTitle} from "@mantine/hooks";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useState} from "react";
import {useModals} from "@mantine/modals";
import {useNavigate} from "react-router-dom";


export const Home = () => {

    useDocumentTitle('Ana Sayfa');

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const modals = useModals();

    const form = useForm({
        initialValues: {
            code: '',
        },

        validate: {
            code: (value) => (value !== '' ? null : 'Geçersiz anket kodu'),
        },
    });





    return (<>

        <div id={"home"}>
            <div className={"table-cell"}>
                <Container size={"xl"}>
                    <Grid>
                        <Grid.Col lg={6}>

                            <Title order={1}>Koçluk Araçlarına <br/> Hoşgeldiniz!</Title>

                            <Text className={"text"} mb={40}>
                                Kurucusu, sponsoru veya üyesi olduğumuz koçluk ve mentorluk dernekleri, farklı markalar
                                altında birlikte
                                yaptığımız üretimler ve işbirlikleri, mezunlarımızın kurup işlettiği yüzlerce takipçisi
                                olan salı
                                buluşmaları, Mentorchat gibi oluşumlarımız, birlikte kitap yazan mezunlarımız ile aynı
                                “ev”de buluşan
                                insanlar grubuyuz. Ayrıntılı bilgi için web sitemizi ziyaret edebilirsiniz.
                            </Text>


                            <form onSubmit={form.onSubmit((values) => navigate(`/anket-giris/${values.code}`))}>

                                <div className={"input-code-group"}>
                                    <div className={"input-left"}>
                                        <TextInput className={"input-code"} placeholder={"Anket Kodu"}
                                                   {...form.getInputProps('code')}
                                                   rightSection={<Tooltip label="Tooltip"><span><AiOutlineQuestionCircle
                                                       color={"#98A2B3"}/></span></Tooltip>}/>
                                        <span
                                            className={"survey-text"}>Anket kodunu ilgili alana yazarak ankete <br/> katılabilirsiniz.</span>
                                    </div>

                                    <Button loading={loading} className={"btn"} type={"submit"} color={"dark"}>Ankete
                                        Katıl</Button>

                                </div>
                            </form>

                        </Grid.Col>
                        <Grid.Col lg={6}>
                            <div className={"home-img"}>
                                <Image alt={'House of Human'} fill src={'/img/home.png'}/>
                            </div>
                        </Grid.Col>
                    </Grid>
                </Container>
            </div>
        </div>

    </>)
}

export default Home
