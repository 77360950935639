import {Button, Center, Container, Divider, Grid, Loader, Stepper, Text, TextInput, Title} from "@mantine/core";

import {useEffect, useState} from "react";
import {useDocumentTitle, useListState} from "@mantine/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {useModals} from "@mantine/modals";
import {getUserInfo, removeUserInfo} from "../../lib/util/auth";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {notifications} from "@mantine/notifications";
import _ from "lodash";


const Swot = () => {


    useDocumentTitle('Anket Soruları');

    const {id} = useParams();
    const modals = useModals();
    const navigate = useNavigate();

    const [survey, setSurvey] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [question, handlers] = useListState([]);
    const [bottomAnswers, setBottomAnswers] = useState([
        {
            type: "bottom_strenght",
            answers: ["", "", "", ""]
        },
        {
            type: "bottom_weakness",
            answers: ["", "", "", ""]
        },
        {
            type: "bottom_opportunities",
            answers: ["", "", "", ""]
        },
        {
            type: "bottom_threat",
            answers: ["", "", "", ""]
        },

    ]);
    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));


    const [answers, setAnswers] = useState([]);

    const [user, setUser] = useState({});


    useEffect(() => {

        let userInfo = getUserInfo();


        if (userInfo) {
            getLoginControl(userInfo);
        } else {
            navigate('/');
        }

    }, []);


    const getLoginControl = (getUser = {}) => {


        Connect().post(`surveys/${id}/check`, getUser).then(({data}) => {

            if (data.status) {

                getSurvey();

                setUser(getUser);

            } else {
                ErrorViewing({message: "Ankete katılımınız uygun değildir"}, modals);
            }


        }).catch((e) => {
            ErrorViewing(e, modals);
            removeUserInfo();
            setTimeout(() => {
                navigate('/');
            }, 300);
        })


    }


    const getSurvey = () => {

        Connect().get(`surveys/${id}`).then(({data}) => {


            setSurvey(data.survey);

            let _question = [];

            data.survey.questions.map((item) => _question.push({
                question_id: item.id,
                answers: ["", ""]
            }));


            handlers.setState(_question);

            let _data = _.chain(data.survey.questions)
                // Group the elements of Array based on `color` property
                .groupBy("type")
                // `key` is group's name (color), `value` is the array of objects
                .map((value, key) => ({type: key, data: value}))
                .value();

            setQuestionList(_data);


            setPageLoading(false);


        }).catch((e) => {
            ErrorViewing(e, modals);
            removeUserInfo();
            setTimeout(() => {
                navigate('/');
            }, 300);
        })

    }


    const getQuestion = (type = '', start = 0, end = 5) => {

        let _data = _.find(questionList, {
            type
        }).data;


        return _data.slice(start, end);


    }


    const updateInput = (item) => {

        let _answers = [...answers];


        let answerItem = _.find(_answers, {
            question_id: item.question_id
        });

        if (answerItem) {

            answerItem.answers = item.first ? [item.value, answerItem.answers[1]] : [answerItem.answers[0], item.value];


        } else {

            let data = [];


            if (item.first) {
                data.push(item.value);
                data.push("");

            } else {
                data.push("");
                data.push(item.value);
            }


            _answers.push({
                question_id: item.question_id,
                answers: data
            });


        }


        setAnswers(_answers);


    }


    const updateBottomInput = (item) => {

        let _bottomAnswers = [...bottomAnswers];


        let find = _.find(_bottomAnswers, {
            type: item.type
        });

        if (find) {

            find.answers[item.index] = item.value

        }


        setBottomAnswers(_bottomAnswers);


    }


    const _setSurvey = () => {

        if (user !== null) {

            user.answers = answers;
            user.bottom_answers = bottomAnswers;

                setLoading(true);

                Connect().post(`surveys/${id}`, user).then(({data}) => {

                    notifications.show({
                        color: 'teal',
                        title: 'Başarılı',
                        message: 'Ankete tamamlandı',
                    });

                    navigate('/anket-sonuc');

                    removeUserInfo();


                }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));




        } else {
            ErrorViewing({message: 'Kullanıcı bilgileri girilmemil'}, modals);
            removeUserInfo();
            navigate('/');
        }


    }


    const resultControl = (index = 0) => {


        let status = true;


        if (survey.questions !== undefined) {


            for (const [key, res] of questionList[index].data.entries()) {

                let find = _.find(answers, {
                    question_id: res.id
                });


                if (!find) {

                    status = false;
                } else if (find.answers[0] === '' || find.answers[1] === '') {
                    status = false
                }
            }

        }


        for (const res of bottomAnswers[index].answers) {


            if (res === '') {
                status = false;
            }


        }


        return status;


    }


    const stepChange = () => {


        if (resultControl(active)) {
            nextStep();
        } else {
            ErrorViewing({message: "Tüm Soruları cevaplamalısınız"}, modals);
        }


    }


    if (pageLoading) {
        return (
            <Center style={{height: 500}}>
                <Loader color={"indigo"}/>
            </Center>
        )
    }





    return (<>


        <div className={"survey-top"}>
            <Container size={"xl"}>
                <b>#{survey?.slug}</b>
                <Title order={1}>{survey?.title}</Title>
                <Text className={"text"}>{survey?.description}</Text>
            </Container>
        </div>


        <div id={"survey-content"}>
            <Container size={"xl"}>
                <Text className={"question-title"}>SWOT, TERS SWOT</Text>
                <Divider my="xl"/>


                <Stepper  className={"hide-stepper"} active={active} onStepClick={setActive} breakpoint="sm">
                    <Stepper.Step label="Step 1" >
                        <div className="table">
                            <Grid gutter={0}>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Soru
                                    </div>
                                    <div className="content-list bottom-content">
                                        {getQuestion("strenght").map((item, index) => (
                                            <div key={`st-item-${index}`} className="content swot-question-content">
                                                <TextInput className={""} disabled value={item?.question}/>
                                            </div>
                                        ))}

                                    </div>

                                </Grid.Col>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Strength
                                    </div>

                                    <div className="content-list bottom-content">

                                        {getQuestion("strenght").map((item, index) => (
                                            <div className="content" key={`answer-top-1-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: true
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}

                                    </div>





                                </Grid.Col>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">

                                    </div>

                                    <div className="content-list bottom-content">

                                        {getQuestion("strenght").map((item, index) => (
                                            <div className="content" key={`answer-top-2-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: false
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}
                                    </div>




                                </Grid.Col>
                                <Grid.Col lg={4}>

                                    <div className="content-list  bottom-content">
                                        <div className="top-title   mt-top" style={{height:193,justifyContent:'center'}}>
                                            Benim zayıf tarafım olabilir çünkü
                                        </div>

                                    </div>


                                </Grid.Col>
                                <Grid.Col lg={8}>

                                    <div className="top-title first-top-title mt-top bottom-item-border">
                                        Weakness
                                    </div>

                                    <div className="content-list bottom-content">
                                        <Grid gutter={0}>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_strenght-0"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_strenght",
                                                        index: 0
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content">
                                                    <TextInput key={"bottom_strenght-1"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_strenght",
                                                        index: 1
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_strenght-2"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_strenght",
                                                        index: 2
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>

                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content">
                                                    <TextInput key={"bottom_strenght-3"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_strenght",
                                                        index: 3
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                        </Grid>




                                    </div>
                                </Grid.Col>

                            </Grid>
                        </div>
                    </Stepper.Step>

                    <Stepper.Step label="Step 2">
                        <div className="table">
                            <Grid gutter={0}>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Soru
                                    </div>
                                    <div className="content-list bottom-content">

                                        {getQuestion("weakness").map((item, index) => (
                                            <div  key={`wk-item-${index}`} className="content swot-question-content">
                                                <TextInput className={""} disabled value={item?.question}/>
                                            </div>
                                        ))}
                                    </div>

                                </Grid.Col>

                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Weakness
                                    </div>

                                    <div className="content-list bottom-content">

                                        {getQuestion("weakness").map((item, index) => (
                                            <div className="content" key={`answer-top-weaknes-1-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: true
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}

                                    </div>


                                </Grid.Col>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">

                                    </div>

                                    <div className="content-list bottom-content">

                                        {getQuestion("weakness").map((item, index) => (
                                            <div className="content" key={`answer-top-weaknes-2-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: false
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}
                                    </div>




                                </Grid.Col>

                                <Grid.Col lg={4}>

                                    <div className="content-list  bottom-content">
                                        <div className="top-title   mt-top" style={{height:193,justifyContent:'center'}}>
                                            Güçlü tarafım olabilir çünkü
                                        </div>
                                    </div>
                                </Grid.Col>
                                <Grid.Col lg={8}>

                                    <div className="top-title first-top-title mt-top bottom-item-border">
                                        Strength
                                    </div>

                                    <div className="content-list bottom-content">
                                        <Grid gutter={0}>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_weakness-0"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_weakness",
                                                        index: 0
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content">
                                                    <TextInput key={"bottom_weakness-1"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_weakness",
                                                        index: 1
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_weakness-2"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_weakness",
                                                        index: 2
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content">
                                                    <TextInput key={"bottom_weakness-3"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_weakness",
                                                        index: 3
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                        </Grid>




                                    </div>
                                </Grid.Col>




                            </Grid>
                        </div>

                    </Stepper.Step>

                    <Stepper.Step label="Step 3" >
                        <div className="table">
                            <Grid gutter={0}>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Soru
                                    </div>
                                    <div className="content-list bottom-content">
                                        {getQuestion("opportunities").map((item, index) => (
                                            <div  key={`op-item-${index}`} className="content swot-question-content">
                                                <TextInput className={""} disabled value={item?.question}/>
                                            </div>
                                        ))}
                                    </div>

                                </Grid.Col>

                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Opportunities
                                    </div>

                                    <div className="content-list bottom-content">

                                        {getQuestion("opportunities").map((item, index) => (
                                            <div className="content" key={`answer-top-opportunities-1-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: true
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}

                                    </div>

                                </Grid.Col>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">

                                    </div>

                                    <div className="content-list bottom-content">
                                        {getQuestion("opportunities").map((item, index) => (
                                            <div className="content" key={`answer-top-opportunities-2-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: false
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}
                                    </div>


                                </Grid.Col>

                                <Grid.Col lg={4}>

                                    <div className="content-list  bottom-content">
                                        <div className="top-title   mt-top" style={{height:193,justifyContent:'center'}}>
                                            Benim için tehdit olabilir çünkü
                                        </div>

                                    </div>





                                </Grid.Col>
                                <Grid.Col lg={8}>

                                    <div className="top-title first-top-title mt-top bottom-item-border">
                                        Threat
                                    </div>

                                    <div className="content-list bottom-content">
                                        <Grid gutter={0}>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_opportunities-0"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_opportunities",
                                                        index: 0
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content">
                                                    <TextInput key={"bottom_opportunities-1"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_opportunities",
                                                        index: 1
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_opportunities-2"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_opportunities",
                                                        index: 2
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content">
                                                    <TextInput key={"bottom_opportunities-3"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_opportunities",
                                                        index: 3
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                        </Grid>
                                    </div>
                                </Grid.Col>

                            </Grid>
                        </div>

                    </Stepper.Step>





                    <Stepper.Step label="Step 4">
                        <div className="table">
                            <Grid gutter={0}>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Soru
                                    </div>
                                    <div className="content-list bottom-content">
                                        {getQuestion("threat").map((item, index) => (
                                            <div  key={`th-item-${index}`} className="content swot-question-content">
                                                <TextInput className={""} disabled value={item?.question}/>
                                            </div>
                                        ))}
                                    </div>

                                </Grid.Col>

                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">
                                        Threat
                                    </div>

                                    <div className="content-list bottom-content">

                                        {getQuestion("threat").map((item, index) => (
                                            <div className="content" key={`answer-top-threat-1-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: true
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}

                                    </div>





                                </Grid.Col>
                                <Grid.Col className={"table-column"} lg={4}>
                                    <div className="top-title first-top-title">

                                    </div>

                                    <div className="content-list bottom-content">
                                        {getQuestion("threat").map((item, index) => (
                                            <div className="content" key={`answer-top-threat-2-${index}`}>
                                                <TextInput onChange={(event) => updateInput({
                                                    question_id: item.id,
                                                    value: event.currentTarget.value,
                                                    index: index,
                                                    first: false
                                                })} placeholder={"Bu alana cevabınızı yazın"}/>
                                            </div>
                                        ))}
                                    </div>


                                </Grid.Col>

                                <Grid.Col lg={4}>

                                    <div className="content-list  bottom-content">
                                        <div className="top-title   mt-top" style={{height:193,justifyContent:'center'}}>
                                            Benim için fırsat olabilir çünkü
                                        </div>
                                    </div>

                                </Grid.Col>
                                <Grid.Col lg={8}>
                                    <div className="top-title first-top-title mt-top bottom-item-border">
                                        Opportunities
                                    </div>

                                    <div className="content-list bottom-content">

                                        <Grid gutter={0}>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_threat-0"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_threat",
                                                        index: 0
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>

                                                <div className="content">
                                                    <TextInput key={"bottom_threat-1"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_threat",
                                                        index: 1
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content border-big-right">
                                                    <TextInput key={"bottom_threat-2"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_threat",
                                                        index: 2
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <div className="content">
                                                    <TextInput key={"bottom_threat-3"} onChange={(event) => updateBottomInput({
                                                        value: event.currentTarget.value,
                                                        type: "bottom_threat",
                                                        index: 3
                                                    })} placeholder={"Bu alana cevabınızı yazın"}/>
                                                </div>
                                            </Grid.Col>
                                        </Grid>




                                    </div>
                                </Grid.Col>



                            </Grid>
                        </div>
                    </Stepper.Step>


                </Stepper>


                {active < 3 &&
                    <Button onClick={() => stepChange()}  style={{marginTop:35}} className={"save-btn"} loading={loading} fullWidth>

                        Sonraki Adıma Geç ({!active ? 1 : active+1}/4)</Button>
                }

                {active === 3 &&
                    <Button onClick={() => _setSurvey()}  style={{marginTop:35}} className={"save-btn"} loading={loading} fullWidth>Anketi Tamamla</Button>
                }
            </Container>
        </div>


    </>)
}

export default Swot
