import axios from "axios";
import { getSession } from "./auth";

// connector
export default function (auth = true) {
  const token = getSession();

  return axios.create({
    baseURL: "https://araclar.houseofhuman.com/api/v1", // process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: auth
      ? {
          Authorization: "Bearer: " + token,
        }
      : {},
  });
}
