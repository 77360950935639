import {Button, Container, Grid, Group,Image} from "@mantine/core";
import {Link} from "react-router-dom";


export const Header = () => {

    return (<header>
        <Container size={"xl"}>
            <Grid>
                <Grid.Col lg={6} span={6} mt={3}>
              <Link to={'/'}>
                  <Image width={114} height={74} alt={"House of Human"} src={'/img/logo.svg'}/>
              </Link>
                </Grid.Col>
                <Grid.Col lg={6} span={6} mt={14}>
                    <Group position={"right"}>
                        <Button component={Link} to={'https://hoh-admin.vercel.app/'} className={"login-btn"} color={"dark"}>Giriş Yap</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Container>
    </header>)

}


export default Header;
