import {Button, Center, Container, Divider, Loader, Text, Title} from "@mantine/core";

import {useEffect, useState} from "react";
import Connect from "../../lib/util/Connect";
import {useDocumentTitle} from "@mantine/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import _ from "lodash";
import {notifications} from "@mantine/notifications";
import {getUserInfo, removeUserInfo, setQuestionInfo} from "../../lib/util/auth";
import QuestionLife from "../../lib/components/Question/life";


const LifeCycle = () => {


    useDocumentTitle('Anket Soruları');

    const {id} = useParams();
    const modals = useModals();
    const navigate = useNavigate();

    const [survey, setSurvey] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [user,setUser] = useState({});

    useEffect(() => {

        let userInfo = getUserInfo();



        if (userInfo) {
            getLoginControl(userInfo);
        } else{
            navigate('/');
        }



    }, []);





    const _setSurvey = () => {


        if (user !== null) {


            user.answers = answers

            if (resultControl()) {


                setLoading(true);

                Connect().post(`surveys/${id}`, user).then(({data}) => {


                    notifications.show({
                        color:'teal',
                        title: 'Başarılı',
                        message: 'Ankete tamamlandı',
                    });

                    let questInfoData = {
                        data:[],
                        answers:[],
                        id:id
                    }

                    survey.questions.map((item,index) =>{
                        questInfoData.data.push({id:item.id,body:item.body,answer:answers[index].answer});
                        questInfoData.answers.push({question_id: item.id, answer: ''});
                    });



                    setQuestionInfo(questInfoData);

                    navigate('/anket-yasam-denge-sonuc');

                  //  removeUserInfo();






                }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));
            } else{
                ErrorViewing({message: 'Tüm soruları cevaplamalısınız'}, modals);
            }

        } else {
            ErrorViewing({message: 'Kullanıcı bilgileri girilmemil'}, modals);
            removeUserInfo();
            navigate('/');
        }


    }

    const resultControl = () => {

        let status = true;


        for (const res of answers) {

            if (res.answer === ''){
                status = false;
            }

        }


        return status;

    }




    const getSurvey = () => {

        Connect().get(`surveys/${id}`).then(({data}) => {


            setPageLoading(false);

            setSurvey(data.survey);

            let _answers = [];


            data.survey.questions.map((item) => _answers.push({question_id: item.id, answer: ''}));


            setAnswers(_answers);

        }).catch((e) => {
            ErrorViewing(e, modals);
            removeUserInfo();
            setTimeout(() => {
                navigate('/');
            }, 300);
        })

    }

    const updateAnswer = (id, val,type = '') => {

        let data = [...answers];



        _.find(data, {
            question_id: id
        }).answer = val;


        setAnswers(data);
    }


    const getLoginControl = (getUser = {}) => {


        Connect().post(`surveys/${id}/check`, getUser).then(({data}) => {

            if (data.status) {

                getSurvey();

                setUser(getUser);

            } else {
                ErrorViewing({message: "Ankete katılımınız uygun değildir"}, modals);
            }


        }).catch((e) => {
            ErrorViewing(e, modals);
            removeUserInfo();
            setTimeout(() => {
                navigate('/');
            }, 300);
        })


    }


    if (pageLoading) {
        return (
            <Center style={{height: 500}}>
                <Loader color={"indigo"}/>
            </Center>
        )
    }


    return (<>


        <div className={"survey-top"}>
            <Container size={"xl"}>
                <b>#{survey?.slug}</b>
                <Title order={1}>{survey?.title}</Title>
                <Text className={"text"}>{survey?.description}</Text>
            </Container>
        </div>


        <div id={"survey-content"}>
            <Container size={"xl"}>
                <Text className={"question-title"}>Soru Listesi</Text>
                <Divider my="xl"/>


                <div className="list">
                    {survey.questions.map((item, index) => {

                        return(
                            <QuestionLife item={item} survey={survey} onChange={(id, val) => updateAnswer(id, val)}
                                          key={`question-${index}`}/>
                        )

                    })}
                </div>

                <Button className={"save-btn"} loading={loading} fullWidth onClick={() => _setSurvey()}>Anketi Tamamla</Button>

            </Container>
        </div>


    </>)
}

export default LifeCycle
