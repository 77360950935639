import React from "react";
import {useLocation} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";


function Content(props) {

    const location = useLocation();


    if (location.pathname === '/giris/') {

        return (<>
            {props.children}
        </>)

    } else {
        return (<>
            <Header/>
            {props.children}
            <Footer/>

        </>);
    }
}

export default Content;
